import React from "react";
import contactStyles from "./contact.module.css";
import Email from "../assets/email.png";
import Phone from "../assets/phone.png";

function Contact() {
	return (
		<section className={contactStyles.contact}>
			<h2 className="title">Contact</h2>
			<div className={contactStyles.wrapper}>
				<div>
					<div className={contactStyles.contactItem}>
						<div>
							<img loading="lazy" src={Phone} alt="phone icon" />
						</div>

						<p>+40 737 915 500</p>
					</div>

					<div className={contactStyles.contactItem}>
						<div>
							<img loading="lazy" src={Email} alt="email icon" />
						</div>
						<p>evaolteanu@yahoo.com</p>
					</div>
				</div>
				<p>
					<b>S.C. ENJOY THE MOTHER NATURE S.R.L. </b>
					<br />
					<b>Sediu:</b> Sat Brebu Mănăstirei, comuna Brebu, strada <br />{" "}
					Ulmului, nr. 4, județul Prahova, <br />
					<b>Registrul Comerţului:</b> J29/1729/13.07.2021 <br />
					<b>Cod fiscal:</b> 44582830 <br />
					<b>Cont Bancar:</b> <span> RO69BTRLRONCRT0609870201 </span> Banva
					Transilvania <br />
					<b>Cod SWIFT:</b> <span> BTRLRO22 </span>
				</p>
			</div>
		</section>
	);
}
export default Contact;
