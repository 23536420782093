import React, { useContext } from "react";
import showcaseStyles from "./showcase.module.css";
import ShowcaseMain from "../assets/showcaseMain.jpeg";
import ShowcaseSecond from "../assets/showcaseSecond.jpeg";
import ShowcaseThird from "../assets/showcaseThird.jpeg";

import { ContextProvider } from "../app";

function Showcase() {
	const ref: any = useContext(ContextProvider);
	return (
		<main ref={ref}>
			<div className={showcaseStyles.wrapper}>
				<img
					loading="lazy"
					src={ShowcaseMain}
					alt="house front view picture"
				/>
				<div className={showcaseStyles.details}>
					<div className={showcaseStyles.info}>
						<h2>Detalii</h2>
						<div>
							<p>Arie:</p>
							<p className={showcaseStyles.area}>45m</p>
						</div>
						<div>
							<p>Număr camere:</p>
							<p>4</p>
						</div>
						<div>
							<p>Facilități:</p>
							<p>2 Dormitoare, Living, Baie</p>
						</div>
						<div>
							<p>Observații:</p>
							<p>Se pot organiza grătare, petreceri tematice</p>
						</div>
						<div>
							<p>
								<b>Preț:</b>
							</p>
							<p>
								<b>600RON / căsuță / zi</b>
							</p>
						</div>
					</div>
					<button>
						<a href="tel:0737915500">
							PENTRU REZERVĂRI: <br /> +40 737 915 500
						</a>
					</button>
				</div>
			</div>
			<div className={showcaseStyles.pic}>
				<img loading="lazy" src={ShowcaseSecond} alt="house lateral view" />
				<img loading="lazy" src={ShowcaseThird} alt="hose back view" />
			</div>
		</main>
	);
}

export default Showcase;
