import React from "react";
import footerStyles from "./footer.module.css";
import footerLogo from "../assets/footerLogo.png";
import Facebook from "../assets/facebook.png";
import styles from "../centerStyles.module.css";

function Footer() {
	return (
		<footer className={footerStyles.footer}>
			<div className={styles.wrapper}>
				<div className={`${styles.container} ${footerStyles.wrapper} `}>
					<img loading="lazy" src={footerLogo} alt="footer logo" />
					<a href="https://www.facebook.com/enjoythemothernature">
						<img loading="lazy" src={Facebook} alt="facebook logo" />
						<p>enjoythemothernature</p>
					</a>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
