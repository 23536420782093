import React, { useContext, useRef } from "react";
import ReactDOM from "react-dom/client";

//pages
import Hero from "./hero/hero";
import About from "./about/about";
import Showcase from "./showcase/showcase";
import Contact from "./contact/contact";
import Footer from "./footer/footer";

//styles
import "./index.css";
import styles from "./centerStyles.module.css";

import { createContext } from "react";

export const ContextProvider = createContext<any>(null);

export default function App() {
	const refCon = useRef();
	return (
		<ContextProvider.Provider value={refCon}>
			<Hero />
			<div className={styles.wrapper}>
				<div className={styles.container}>
					<About />
					<Showcase />
					<Contact />
				</div>
			</div>
			<Footer />
		</ContextProvider.Provider>
	);
}
