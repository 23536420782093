import React from "react";
import aboutStyles from "./about.module.css";

function About() {
	return (
		<section className={aboutStyles.about}>
			<h2 className="title">Cine suntem?</h2>
			<p>
				<b>ENJOY THE MOTHER NATURE</b> este o întreprindere socială ce îşi
				propune să ajute copii proveniţi din familii vulnerabile care în
				lipsa unui sprijin real vor îngroşa rândul tinerilor neşcolarizaţi,
				necalificaţi şi cu viitor incert. Entitatea îşi propune să realizeze
				acest obiectiv prin redirecţionarea profitului întreprinderii (minim
				70% din profit). Totodată angajarea de personal din zona familiilor
				vulnerabile poate fi pusă în stransă legatură cu ţinta socială a
				întreprinderii. Astfel, pe langă faptul că intenţionăm să ajutăm
				copilul/copiii familiei, putem interveni în viaţa întregii familii
				prin asigurarea unui loc de muncă pentru unul sau ambii părinţi.
			</p>

			<p>
				Întreprinderea socială are ca obiect prestarea de servicii de cazare
				de scurtă durată în locuinţe separate, independente constând in 4
				case tip container modular, demontabile și transportabile, complet
				mobilate.
			</p>

			<p>
				<b>*</b> Căsuțele se află în{" "}
				<b>
					comuna Belciugatele, sat. Cojești, str. Preot Mateescu Octavian,
					nr. 64, jud Călărași.
				</b>
			</p>
		</section>
	);
}

export default About;
