import React, { useContext } from "react";
import Logo from "../assets/headerLogo.png";
import Separator from "../assets/separator.svg";
import heroStyles from "./hero.module.css";

import centerStyles from "../centerStyles.module.css";

import { ContextProvider } from "../app";

function Hero() {
	const ref: any = useContext(ContextProvider);
	return (
		<header className={heroStyles.wrapper}>
			<div className={`${heroStyles.opBackground} ${centerStyles.wrapper}`}>
				<div className={centerStyles.container}>
					<div className={heroStyles.content}>
						<img src={Logo} alt="logo" className={heroStyles.logo} />
						<h1>ÎNCHIRIERI CĂSUȚE MOBILE</h1>
						<button
							onClick={() => {
								ref.current.scrollIntoView({ behavior: "smooth" });
							}}
						>
							REZERVĂ ACUM
						</button>
					</div>
				</div>
				<img
					loading="lazy"
					src={Separator}
					alt="separator"
					className={heroStyles.separator}
				/>
			</div>
		</header>
	);
}

export default Hero;
